export default [
  {
    title: "s3rver",
    source: "https://github.com/jamhall/s3rver/",
    description:
      "S3rver is a lightweight server that responds to some of the same calls Amazon S3 responds to. It is extremely useful for testing S3 in a sandbox environment without actually making calls to Amazon.",
  },
  {
    title: "preql",
    source: "https://github.com/ILLGrenoble/preql",
    description:
      "Preql (Predicate query language) is a project designed to filter JPA collections using client-side expressions.",
  },
  {
    title: "ngx-remote-desktop",
    path: "https://illgrenoble.github.io/ngx-remote-desktop/index.html",
    source: "https://github.com/ILLGrenoble/ngx-remote-desktop",
    description:
      "An Angular component for connecting to a remote desktop using the guacamole remote desktop gateway",
  },
  {
    title: "guacamole-test-server",
    source: "https://github.com/jamhall/guacamole-test-server",
    description: "A server for testing remote desktop connections to guacd",
  },
  {
    title: "jamiehall.eu",
    path: "https://jamiehall.eu",
    source: "https://github.com/jamhall/site",
    description: "The source code of this website",
  },
]
