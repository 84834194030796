import React, { Component } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import moment from "moment"
import { formatDate } from "src/helpers"
import { Tags } from "src/components"

export default class Posts extends Component {
  isProduction() {
    return process.env.NODE_ENV === "production"
  }

  getPostList() {
    const { postEdges } = this.props
    const posts = postEdges
      .filter(postEdge => postEdge.node.frontmatter.template === "post")
      .map(postEdge => postEdge.node)
      .map(node => {
        return {
          path: node.fields.slug,
          tags: node.frontmatter.tags,
          thumbnail: node.frontmatter.thumbnail,
          title: node.frontmatter.title,
          description: node.frontmatter.description,
          date: node.fields.date,
          excerpt: node.excerpt,
          timeToRead: node.timeToRead,
          readingTime: node.fields.readingTime,
          categories: node.frontmatter.categories,
          published: node.frontmatter.published,
          tags: node.frontmatter.tags,
        }
      })

    if (this.isProduction()) {
      return posts.filter(post => post.published)
    }
    return posts
  }

  // eslint-disable-next-line class-methods-use-this
  isNewest(date) {
    return moment(date) > moment().subtract(1, "months")
  }

  // eslint-disable-next-line class-methods-use-this
  renderThumbnail(thumbnail) {
    if (thumbnail) {
      return (
        <div className="card-image">
          <Img fixed={thumbnail} className="thumbnail" />
        </div>
      )
    }
    return null
  }

  renderTitle(post) {
    const { title, path } = post
    return (
      <Link to={`/posts/${path}`} className="card-title">
        {title}
      </Link>
    )
  }

  renderBody(post) {
    const { description, date, readingTime, tags } = post
    const thumbnail = post.thumbnail
      ? post.thumbnail.childImageSharp.fixed
      : null
    return (
      <div className="card-block">
        {this.renderThumbnail(thumbnail)}
        <div className="card-body">
          <span className="card-text">{description}</span>
          <div className="card-meta">
            <span>{formatDate(date)}</span>
            <span className="dot"></span>
            <span>{readingTime.text}</span>
          </div>
        </div>
      </div>
    )
  }

  renderExcept(date) {
    const { simple } = this.props
    if (simple) {
      return null
    }
    return <div className="excerpt">{formatDate(date)}</div>
  }

  // eslint-disable-next-line class-methods-use-this
  renderNewest(newest) {
    if (newest) {
      return (
        <div className="alert">
          <div className="new">New!</div>
        </div>
      )
    }
    return null
  }

  renderTags(post) {
    return (
      <div className="card-tags">
        <Tags size="sm" tags={post.tags} />
      </div>
    )
  }
  render() {
    let posts = this.getPostList()
    return (
      <div>
        {posts.map(post => {
          const newest = this.isNewest(post.date)
          return (
            <div className="card-box" key={post.title}>
              <div className="card">
                {this.renderTitle(post)}
                {this.renderBody(post)}

                {this.renderTags(post)}
                {/* {this.renderNewest(newest)} */}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

Posts.defaultProps = {
  simple: null,
  showTags: false,
}
