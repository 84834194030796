import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, withPrefix, graphql } from "gatsby"
import "../styles/app.scss"
import { Header } from "src/components"
import { config } from "data"
import ThemeContext from "src/context/theme-context"
import Helmet from "react-helmet"

export default class Layout extends Component {
  static contextType = ThemeContext

  constructor(props) {
    super(props)
  }

  isDevelopment() {
    return process.env.NODE_ENV === "development"
  }

  renderGlobalMessage() {
    if (this.isDevelopment()) {
      return (
        <div className="flash flash-error flash-error--global">
          Running in development mode
          <button className="flash-close">Close</button>
        </div>
      )
    }
    return null
  }

  render() {
    const { dark, toggleDark } = this.context
    const theme = dark ? "dark" : "light"
    const children = this.props.children
    const { menuLinks, siteTitle } = config
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              buildTime(formatString: "YYYY-MM-DD LT")
            }
          }
        `}
        render={data => (
          <div>
            <Helmet
              bodyAttributes={{
                "data-theme": `${theme}`,
              }}
            >
              <script src={withPrefix("js/app.js")} type="text/javascript" />
              <meta name="description" content={config.siteDescription} />
            </Helmet>
            <Header
              title={siteTitle}
              dark={dark}
              toggleTheme={toggleDark}
              links={menuLinks}
            />
            <div className="container">
              <main>{children}</main>
            </div>
            <footer className="footer">
              <div className="footer-container">
                <div className="footer-links">
                  <a href={config.github}>GitHub</a>
                  <a href="/rss.xml">RSS</a>
                </div>
                <div className="footer-copyright">
                  &copy; 2019 {config.author}
                </div>
              </div>
            </footer>
            {this.renderGlobalMessage()}
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
