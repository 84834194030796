import React, { Component } from "react"
import GitHubButton from "react-github-btn"

export default class Projects extends Component {
  isProduction() {
    return process.env.NODE_ENV === "production"
  }

  render() {
    const { projects } = this.props
    return (
      <section>
        {projects.map(project => (
          <div key={project.source} className="card-box">
            <div className="card">
              <a className="card-title" href={project.source}>
                {project.title}
              </a>
              <div className="card-block">
                <div className="card-body">
                  <span className="card-text">{project.description}</span>
                </div>
              </div>
            </div>
            <div className="card-actions">
              {project.path && (
                <a
                  className="btn"
                  href={project.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Site
                </a>
              )}
              <GitHubButton
                href={project.source}
                data-icon="octicon-star"
                data-size="large"
                aria-label="Star ntkme/github-buttons on GitHub"
              >
                Star
              </GitHubButton>
            </div>
          </div>
        ))}
      </section>
    )
  }
}
