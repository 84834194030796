import React, { Component } from "react"
import Helmet from "react-helmet"
import { config } from "data"

export default class SEO extends Component {
  createSchema() {
    const schema = [
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        url: config.siteUrl,
        name: config.siteTitle,
      },
    ]
    return JSON.stringify(schema)
  }

  render() {
    const schema = this.createSchema()
    const title = this.props.title || config.siteTitle
    return (
      <Helmet>
        <meta name="description" content={config.siteDescription} />
        <script type="application/ld+json">{schema}</script>

        <meta property="og:url" content={config.siteUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={config.siteDescription} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={config.userTwitter} />
        <meta name="twitter:title" content={config.siteTitle} />
        <meta name="twitter:description" content={config.siteDescription} />
      </Helmet>
    )
  }
}
