import React, { Component } from "react"
import { Link } from "gatsby"

export default class Tags extends Component {
  render() {
    const { tags } = this.props
    const size = this.props.size || "normal"
    return (
      <div className={"tags tags-" + size}>
        {tags &&
          tags.map(tag => (
            <Link
              key={tag}
              className="tag"
              to={`/posts/?refinementList[tags][0]=${tag}`}
            >
              {tag}
            </Link>
          ))}
      </div>
    )
  }
}
