const config = {
  siteTitle: "Jamie Hall",
  siteTitleShort: "Jamie Hall",
  siteUrl: "https://jamiehall.eu",
  siteLogo: "/logos/jamie.jpg",
  repo: "https://github.com/jamhall/jamiehall.eu",
  github: "http://github.com/jamhall",
  githubUsername: "jamhall",
  pathPrefix: "",
  themeColor: "#FD6A08",
  dateFromFormat: "YYYY-MM-DD",
  dateFormat: "MMMM Do, YYYY",
  siteDescription:
    "Jamie Hall is a software developer based in Grenoble, France",
  intro1: "Hi 👋🏼 I'm Jamie Hall, a software developer based in France.",
  intro2:
    " I write articles about tech topics that interest me. I mostly focus on web and systems development.",
  intro3: `The easiest way to contact me is by <a href="mailto:hello@jamiehall.eu">email</a>.`,
  license: `Content on jamiehall.eu is licensed under a <a href="http://creativecommons.org/licenses/by-sa/4.0/">Creative Commons Attribution-ShareAlike 4.0 International License</a>`,
  siteRss: "/rss.xml",
  userEmail: "hello@jamiehall.eu",
  author: "Jamie Hall",
  googleAnalyticsID: "UA-50597448-1",
  menuLinks: [
    {
      name: "Posts",
      link: "/posts",
    },
  ],
}

module.exports = config
