import { Link } from "gatsby"
import React, { Component } from "react"

export default class Header extends Component {
  constructor(props) {
    super(props)
  }

  renderLightIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon-sun"
      >
        <circle cx="12" cy="12" r="5"></circle>
        <line x1="12" y1="1" x2="12" y2="3"></line>
        <line x1="12" y1="21" x2="12" y2="23"></line>
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
        <line x1="1" y1="12" x2="3" y2="12"></line>
        <line x1="21" y1="12" x2="23" y2="12"></line>
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
      </svg>
    )
  }

  renderDarkIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon-moon"
      >
        <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
      </svg>
    )
  }

  renderThemeButton() {
    if (this.props.dark) {
      return this.renderLightIcon()
    }
    return this.renderDarkIcon()
  }

  render() {
    const { links, title } = this.props
    return (
      <header>
        <nav className="nav">
          <div className="nav-container">
            <div className="brand">
              <Link to="/">
                <span className="text">{title}</span>
              </Link>
            </div>
            <div className="links">
              {links.map(link => (
                <Link key={link.name} to={link.link} activeClassName="active">
                  {link.name}
                </Link>
              ))}
            </div>
            <div className="theme-toggle">
              <button
                className="toggle-button"
                aria-label="Toggle theme"
                onClick={this.props.toggleTheme}
              >
                {this.renderThemeButton()}
              </button>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

Header.defaultProps = {
  dark: false,
}
