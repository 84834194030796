import moment from "moment"
import { config } from "../../data"

const formatDate = date => moment.utc(date).format(config.dateFormat)

const toKebabCase = input => {
  return input
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .toLowerCase()
}

export { formatDate, toKebabCase }
